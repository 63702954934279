import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: ${({ direction }) => (direction === "right" ? "row-reverse" : "row")};
    justify-content: center;
	align-items: center;
	gap: 40px;

	> div {
		width: 50%;
	}

	@media (max-width: 768px) {
		gap: 20px;
		flex-direction: column-reverse;

		> div {
			width: 100%;
		}
  	}
`;

export const Content = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;

	.title {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 20px;
		color: ${(props) => props.theme.black};
		max-width: 500px;
		text-wrap: balance;
		margin-bottom: 40px;

		h2 {
			font-size: 60px;
			line-height: 1.2;
			text-transform: uppercase;
			font-weight: 800;
			z-index: 2;
		}
		h3 {
			font-size: 28px;
			font-weight: 600;
		}
		p {
			font-size: 20px;
			font-weight: 400;
		}
	}

	.icon-corner {
		position: absolute;
		top: -20px;
        right: 0;
		width: 80px;
		height: 80px;
		color: ${(props) => props.theme.primary};
	}

	@media (max-width: 1366px) {
		.title {
			max-width: 400px;
			h2 {
				font-size: 48px;
			}
			p {
				font-size: 16px;
			}
		}
	}

	@media (max-width: 768px) {
		.title {
			text-align: center;
			h2 {
				font-size: 28px;
			}
			p {
				font-size: 14px;
				text-align: justify;
				text-wrap: pretty;
			}
		}
		.icon-corner {
			display: none;	
		}
  	}
`;

export const Image = styled.div`
	img {
		width: 100%;
		margin: auto;
	}
`;
