/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Container, Section } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import Icons from "../Icons2";
import InternalLink from "../InternalLink";
import { Wrapper, Content, Image } from "./style";

function CTANew({ config, direction }) {
	if (!config?.length) return null;

	const { title, paragraph, image, cta, link } = config[0];

	return (
		<Section>
			<Container large>
				<Wrapper direction={direction}>
					<Content>
						<div className="title">
							<h2>{title}</h2>
							<p>{paragraph}</p>
							<Icons icon="ntx-corner-rt" className="icon-corner" />
						</div>
						{(cta && link) && (
							<InternalLink to={link}>
								<Button primary large>
									{cta}
								</Button>
							</InternalLink>
						)}
					</Content>
					<Image>
						<LazyLoadImage
							src={image.img}
							alt={title}
						/>
					</Image>
				</Wrapper>
			</Container>
		</Section>
	);
}

export default CTANew;
